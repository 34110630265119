<template>
    <section class="login container flex-center">
        <div class="row w-100 flex-center">

            <div class="col-12 col-sm-9 col-md-7 col-lg-5 text-center px-3 py-4 p-sm-5" 
                style="background-color: #FFFFFFFD; border-radius: 8px;">

                <span class="ge--description-sm">
                    <b>Mi portal</b>
                </span>
                    
                <img :src="img_logo" class="img-fluid mb-2">

                <ge-input v-model="phone" 
                    placeholder="Teléfono" 
                    :max_length="10" 
                    :disabled="phone_found"
                    class="mb-4"/>

                <ge-input v-model="code" 
                    placeholder="Contraseña" 
                    type="password" 
                    class="mb-5"
                    v-if="phone_found"/>

                <!-- <div class="flex-start-center pb-4">
                    <input type="checkbox" name=""> 
                    <div class="ps-2">
                        <span class="ge--description-xs">
                            <small><b>Recordarme</b></small>
                        </span>
                    </div>
                </div> -->

                <button @click="selectAction()" class="btn btn-primary btn-pill w-100 mt-4 mb-2">
                    <span class="ge--description-sm text-white">
                        {{ (!phone_found) ? 'Buscar' : 'Ingresar' }}
                    </span>
                </button>

                <!-- <div class="text-start">
                    <span class="ge--description-xs text-primary">
                        <small><b>Recuperar usuario y contraseña</b></small>
                    </span>    
                </div> -->

            </div>
        
        </div>
    </section>
</template>

<script setup>
    import { ref, inject } from 'vue'
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex"
    // Images
    const img_logo = ref(require('ASSETS/images/logo'))

    // Hooks
    const router = useRouter()
    const store = useStore()

    // Injects
    const $sendRequest = inject('$sendRequest')
    const $redirect = inject('$redirect')

    // Computeds

    // Variables
    const phone = ref(null)
    const code = ref(null)
    const phone_found = ref(null)

    // Methods

    const selectAction = async() => {
        store.dispatch('showLoading')

        if(phone_found.value) {
            login()
        }
        else {
            verification()
        }
    }

    const verification = async() => {
        let body = {
            phone: phone.value
        }

        let response = await $sendRequest('login', 'POST', body)

        if(response.success && response.data.access) {
            // code.value = response.data.code
            phone_found.value = true
            store.dispatch('showAlert', 'Su contraseña ha sido enviada a su correo electrónico')
            store.dispatch('hiddenLoading')
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_empty_phone') {
                    message.push('El campo "Teléfono" es requerido')
                }
                if(error.code == 'error_not_found_patient') {
                    message.push('Usuario no encontrado')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

    const login = async() => {
        let body = {
            code: code.value
        }

        let response = await $sendRequest('verification', 'POST', body)
        // console.log(response, 'response login')

        if(response.success && response.data.session) {
            await store.dispatch('createSession', {
                session: response.data.session,
                type: 'client'
            })
            store.dispatch('showAlert', 'Bienvenido')
            store.dispatch('hiddenLoading')
            $redirect('dashboard')
        }
        else if(!response.success && response.errors) {
            let message = []
            for(let error of response.errors) {
                if(error.code == 'error_not_found_patient') {
                    message.push('Contraseña incorrecta')
                }
                else {
                    message.push('Ha ocurrido un error')
                }
            }
            store.dispatch('showError', message)
            store.dispatch('hiddenLoading')
        }
        else {
            store.commit('setLoad', { 
                show: true, 
                title: 'Conexión perdida', 
                subtitle: 'Por favor refrescar la página' 
            })
        }
    }

</script>

<style>
    .login {
        min-height: calc(100vh - 48px);
    }
</style>